import { ButtonGroup, Flex, IconButton, Input, InputGroup, InputLeftElement, Spinner } from '@chakra-ui/react'
import { arrayMove } from '@dnd-kit/sortable'
import { ColumnOrderState, Table } from '@tanstack/react-table'
import { FaExpand } from 'react-icons/fa'
import { MdFilterListAlt, MdOutlineSearch } from 'react-icons/md'
import TableColumnOrder from './TableColumnOrder'

export type tablecontrols = {
  showFilters: boolean
  showColumns: boolean
  showGlobalFilter: boolean
  showSorting: boolean
  tvMode: boolean
}

export type tableControlsProps = {
  tableControls: tablecontrols
  onGlobalFilterChange?: (value: string) => void
  globalFilterValue?: string
  table: Table<any>
  columnVisibility: Record<string, boolean>
  setColumnOrder: React.Dispatch<React.SetStateAction<ColumnOrderState>>
  onTableToggleColumnsFilters: () => void
  isFetching?: boolean
  renderLeftControls?: (table?: Table<any>) => JSX.Element
  onTvModeChange?: () => void
}

const TableControls = ({
  onGlobalFilterChange,
  globalFilterValue,
  table,
  columnVisibility,
  setColumnOrder,
  tableControls,
  onTableToggleColumnsFilters,
  isFetching = false,
  renderLeftControls,
  onTvModeChange,
}: tableControlsProps): JSX.Element => {
  return (
    <Flex justifyContent="space-between">
      {renderLeftControls && renderLeftControls(table)}
      <Flex gridGap={2} align="center">
        {isFetching ? <Spinner size="sm" /> : null}
        <InputGroup>
          <InputLeftElement pointerEvents="none">
            <MdOutlineSearch size={16} color="gray.500" />
          </InputLeftElement>
          <Input
            maxW={300}
            placeholder="Pesquisar..."
            onChange={e => onGlobalFilterChange?.(e.target.value)}
            value={globalFilterValue}
            borderColor="gray.300"
            size="md"
          />
        </InputGroup>

        <ButtonGroup size="md" isAttached variant="outline">
          {/* <IconButton aria-label="show map" icon={<FaMap />} /> */}
          <IconButton
            aria-label="tv mode"
            icon={<FaExpand />}
            onClick={onTvModeChange}
            colorScheme={tableControls.tvMode ? 'blue' : 'gray'}
            bg={tableControls.tvMode ? 'blackAlpha.100' : ''}
          />
          <IconButton
            aria-label="reset table"
            icon={<MdFilterListAlt />}
            onClick={onTableToggleColumnsFilters}
            colorScheme={tableControls.showFilters ? 'blue' : 'gray'}
            bg={tableControls.showFilters ? 'blackAlpha.100' : ''}
          />
        </ButtonGroup>
        <TableColumnOrder
          keyValue={Object.entries(columnVisibility)
            .filter(c => !c[1])
            .join('-')}
          columns={table.getAllLeafColumns().map(column => ({
            column: column.columnDef.header as string,
            columnId: column.id,
            onVisibility: column.getIsVisible(),
            onVisibilityToggle: column.toggleVisibility,
          }))}
          onColumnOrderChange={(active, over) => {
            setColumnOrder(prevOrder => {
              return arrayMove(prevOrder, prevOrder.indexOf(active), prevOrder.indexOf(over))
            })
          }}
        />
      </Flex>
    </Flex>
  )
}

export default TableControls
