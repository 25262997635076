import {
  Badge,
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  Icon,
  IconButton,
  Progress,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from '@chakra-ui/react'
import {
  addDays,
  format,
  isBefore,
  isToday,
  nextSunday,
  parseISO,
  setHours,
  setMinutes,
  setSeconds,
} from 'date-fns'
import { useEffect, useMemo, useRef, useState } from 'react'
import { FaUserGear } from 'react-icons/fa6'
import { RiArrowLeftLine, RiArrowRightLine, RiMenuLine } from 'react-icons/ri'
import { Link } from 'react-router-dom'
import { useSidebarDrawer } from '../../../contexts/SidebarDrawerContext'
import { Logo } from '../../../layout/Header/Logo'
import { Profile } from '../../../layout/Header/Profile'
import { Sidebar } from '../../../layout/Sidebar'
import { useGetTotalCteValues } from '../../../services/endpoints/datamex/getTotalCteValues'
import { FormattedAllFreights, GetFreightsResponse } from '../../../services/endpoints/freights/getFreights'
import { useGetFreightsToOperation } from '../../../services/endpoints/freights/getfreightsOpreration'
import getOperationStatus from '../../../services/utils/getOperationStatus'
import isWeekday from '../../../services/utils/isWeekday'
import parseCurrency from '../../../services/utils/parseCurrency'

function splitArray(array: any[], size: number) {
  const result = []
  for (let i = 0; i < array.length; i += size) {
    result.push(array.slice(i, i + size))
  }
  return result
}

interface FormattedAllFreightsOperation extends FormattedAllFreights {
  rowColor: string
}
export function List(): JSX.Element {
  const { onToggle } = useSidebarDrawer()
  const [currentShow, setCurrentShow] = useState(0)

  const bg = useColorModeValue('white', 'gray.800')
  const rowColorWhenIsCollectAfterToday = useColorModeValue('blue.200', 'blue.700')

  const {
    data: Current,
    isLoading: isLoadingCurrent,
    isFetching: isFetchingCurrent,
  } = useGetFreightsToOperation({
    status: 'current',
    per_page: 100,
  })
  const {
    data: Active,
    isLoading: isLoadingActive,
    isFetching: isFetchingActive,
  } = useGetFreightsToOperation({
    status: 'active',
    per_page: 100,
  })

  const dateNow = format(new Date(), 'yyyy-MM-dd')

  const { data: cteValuesDay } = useGetTotalCteValues({
    emit_date_ini: dateNow,
    emit_date_final: dateNow,
  })

  const memoizedFreightData = useMemo(() => {
    const freights = [...(Current?.data || []), ...(Active?.data || [])] as GetFreightsResponse['data']

    const freightsFiltered = {
      all: [],
      collecetToday: [],
      collecetTomorrow: [],
    } as {
      collecetToday: FormattedAllFreightsOperation[]
      collecetTomorrow: FormattedAllFreightsOperation[]
      all: FormattedAllFreightsOperation[]
    }

    const now = new Date()
    const todayAtNoon = setSeconds(setMinutes(setHours(addDays(now, 0), 23), 59), 59)
    const middledayAtNoon = setSeconds(setMinutes(setHours(addDays(now, 1), 12), 0), 0)
    const weekdays = isWeekday(now)
    const nextSundayDate = nextSunday(now)
    const nextSundayAtNoon = setSeconds(setMinutes(setHours(nextSundayDate, 23), 59), 59)
    const hasCte = (freight: FormattedAllFreights) => !!freight.ctes?.length

    freights.forEach(freight => {
      const finishedFreight =
        freight.formatted_status === 'Entrega Finalizada' ||
        freight.formatted_status === 'Chegou para descarregar'

      const collectIsBeforeToday =
        freight.formatted_status === 'Começou a viagem' && !isToday(parseISO(freight.collect_cargo_at || ''))

      if (finishedFreight || collectIsBeforeToday) {
        return
      }

      if (weekdays) {
        if (isBefore(parseISO(freight.collect_cargo_at || ''), todayAtNoon)) {
          freightsFiltered.all.push({ ...freight, rowColor: '' })
          if (hasCte(freight)) return
          freightsFiltered.collecetToday.push({ ...freight, rowColor: '' })
          return
        }
        if (isBefore(parseISO(freight.collect_cargo_at || ''), middledayAtNoon)) {
          if (hasCte(freight)) return
          freightsFiltered.collecetTomorrow.push({
            ...freight,
            rowColor: rowColorWhenIsCollectAfterToday,
          })
          return
        }
      }

      if (!weekdays) {
        // até hoje 00:00
        if (isBefore(parseISO(freight.collect_cargo_at || ''), todayAtNoon)) {
          if (hasCte(freight)) return
          freightsFiltered.all.push({ ...freight, rowColor: '' })
          freightsFiltered.collecetToday.push({ ...freight, rowColor: '' })
          return
        }
        // entre hoje e domingo 23:59
        if (isBefore(parseISO(freight.collect_cargo_at || ''), nextSundayAtNoon)) {
          if (hasCte(freight)) return
          freightsFiltered.collecetTomorrow.push({
            ...freight,
            rowColor: rowColorWhenIsCollectAfterToday,
          })
        }
      }
    })

    const allFreights = [
      ...(freightsFiltered.collecetToday || []),
      ...(freightsFiltered.collecetTomorrow || []),
    ]

    const freightTotal = allFreights.length
    const arraySplited: FormattedAllFreightsOperation[][] = splitArray(allFreights, 8)
    const reduceServicePrice = allFreights.reduce((acc, freight) => {
      if (freight.rowColor !== '') {
        return acc
      }
      return acc + Number(freight.service_price ? freight.service_price : 0)
    }, 0)

    let countWithCte = 0
    const totalValueToday = freightsFiltered.collecetToday.reduce((acc, freight) => {
      return acc + Number(freight.service_price ? freight.service_price : 0)
    }, 0)

    const totalWithCte = freightsFiltered.all.reduce((acc, freight) => {
      if (hasCte(freight)) {
        countWithCte += 1
        return acc + Number(freight.service_price ? freight.service_price : 0)
      }
      return acc
    }, 0)

    const totalWithoutCte = freightsFiltered.all.length - countWithCte

    return {
      freightTotal,
      reduceServicePrice,
      arraySplited,
      allFreights: allFreights || [],
      totalFreightsToday: freightsFiltered.collecetToday.length,
      totalValueToday,
      totalWithoutCte,
      totalWithCte,
      countWithCte,
      countWithoutCte: freightsFiltered.all.length - countWithCte,
      valueWithoutCte: totalValueToday - totalWithCte,
      allFreightToday: freightsFiltered.collecetToday.length,
    }
  }, [Current, Active, rowColorWhenIsCollectAfterToday])

  const [isRunning] = useState(true)
  const intervalRef = useRef<number | null>(null)

  useEffect(() => {
    function countDownInterval() {
      const limit = memoizedFreightData.arraySplited.length
      setCurrentShow(prev => (prev === limit - 1 ? 0 : prev + 1))
    }
    const TEN_SECONDS = 10000

    if (isRunning) {
      intervalRef.current = window.setInterval(countDownInterval, TEN_SECONDS)
    }

    return () => {
      if (intervalRef.current !== null) {
        clearInterval(intervalRef.current)
      }
    }
  }, [memoizedFreightData, isRunning])

  const handleNext = () => {
    setCurrentShow(prev => (prev === memoizedFreightData.arraySplited.length - 1 ? 0 : prev + 1))
  }

  const handlePrevious = () => {
    setCurrentShow(prev => (prev === 0 ? memoizedFreightData.arraySplited.length - 1 : prev - 1))
  }

  return (
    <Box p="6">
      <Sidebar SidebarMobile />
      <Flex align="center" gridGap={3} justifyContent="space-between">
        <Flex align="center">
          <IconButton
            mt={2}
            aria-label="Open navigation"
            icon={<Icon as={RiMenuLine} />}
            fontSize="24"
            variant="link"
            onClick={onToggle}
          />

          <Logo />
        </Flex>
        <Heading size="md">Status Operação {isFetchingActive && isFetchingCurrent && <Spinner />}</Heading>

        <Profile />
      </Flex>

      <Box mt={8} bg={bg} minH="70vh">
        {isLoadingCurrent && isLoadingActive ? (
          <Flex height={300} justifyContent="center" alignItems="center">
            <Spinner />{' '}
          </Flex>
        ) : null}
        {!isLoadingCurrent && !isLoadingActive ? (
          <TableContainer overflow="hidden" rounded="md">
            <Table variant="simple" size="md" overflow="hidden" rounded="md">
              <Thead>
                <Tr>
                  <Th>Frete</Th>
                  <Th>Coleta</Th>
                  <Th>Valor</Th>
                  <Th>Cliente</Th>
                  <Th>Operador</Th>
                  <Th>Motorista</Th>
                  <Th>Status</Th>
                </Tr>
              </Thead>
              <Tbody>
                {memoizedFreightData.arraySplited.length > 0 &&
                  memoizedFreightData.arraySplited[currentShow]?.map(freight => {
                    const status = getOperationStatus(freight)

                    const shortName = (name: string) => {
                      const nameArray = name.split(' ')
                      if (nameArray.length > 1) {
                        return `${nameArray[0]}. ${nameArray[1][0]}`
                      }
                      return name
                    }

                    return (
                      <Tr key={freight.freight_number} bg={freight.rowColor || ''}>
                        <Td fontWeight="bold">
                          <Text
                            ml="1"
                            fontWeight="bold"
                            cursor="pointer"
                            as={Link}
                            to={`/freights/show/${freight.id}`}
                          >
                            #{freight.freight_number}
                          </Text>
                        </Td>
                        <Td fontWeight="bold">
                          {format(parseISO(freight.collect_cargo_at || ''), 'dd/MM HH:mm')}
                        </Td>
                        <Td>
                          <Badge variant="outline" colorScheme="green" size="md" fontSize="0.8em">
                            {freight.service_price && parseCurrency(freight.service_price)}
                          </Badge>
                        </Td>
                        <Td fontWeight="bold" whiteSpace="nowrap" minW={250}>
                          {freight.client.nickname}
                        </Td>

                        <Td>
                          <Badge colorScheme="purple" variant="outline">
                            <Flex gridGap="2" alignItems="center" fontSize="0.8rem">
                              <FaUserGear />
                              {shortName(freight.creator.name)}{' '}
                            </Flex>
                          </Badge>
                        </Td>
                        <Td>
                          <Badge colorScheme={freight.motorist_id ? 'green' : 'red'} rounded="sm" px="2">
                            {freight.motorist_id ? 'SIM' : 'NÃO'}
                          </Badge>
                        </Td>

                        <Td fontSize="16px">
                          <Badge fontSize="0.8rem" color={status.color}>
                            {status.status}
                          </Badge>
                        </Td>
                      </Tr>
                    )
                  })}
              </Tbody>
            </Table>
          </TableContainer>
        ) : null}
      </Box>
      <Progress
        colorScheme="green"
        size="sm"
        value={((currentShow + 1) * 100) / memoizedFreightData.arraySplited.length}
      />
      <Divider my={2} />
      <Flex justifyContent="space-between" gridGap={4}>
        <Flex gridGap={4}>
          <Flex gridGap={6} display="flex" borderWidth={1} borderColor="gray.300" p={2} rounded="md">
            <Box>
              <Heading size="xs">Nº Fretes</Heading>
              <Text fontSize="2xl" fontWeight="bold" mt={1}>
                {memoizedFreightData.allFreightToday + (cteValuesDay?.totalFreights || 0)}
              </Text>
            </Box>
            <Box>
              <Heading size="xs">Nº Fretes: CTE emitidos</Heading>
              <Text fontSize="2xl" fontWeight="bold" mt={1}>
                {cteValuesDay?.totalFreights}
              </Text>
            </Box>
            <Box>
              <Heading size="xs">Nº Fretes: CTE não emitidos</Heading>
              <Text fontSize="2xl" fontWeight="bold" mt={1}>
                {memoizedFreightData.allFreightToday}
              </Text>
            </Box>
          </Flex>
          <Flex gridGap={6} display="flex" borderWidth={1} borderColor="gray.300" p={2} rounded="md">
            <Box>
              <Heading size="xs">Total: Fretes</Heading>
              <Text fontSize="2xl" fontWeight="bold" mt={1}>
                {parseCurrency(memoizedFreightData.totalValueToday + (cteValuesDay?.totalValue || 0))}
              </Text>
            </Box>
            <Box>
              <Heading size="xs">Total: Emitido (R$)</Heading>
              <Text fontSize="2xl" fontWeight="bold" mt={1}>
                {parseCurrency(cteValuesDay?.totalValue || 0)}
              </Text>
            </Box>
            <Box>
              <Heading size="xs">Total : Não emitido(R$)</Heading>
              <Text fontSize="2xl" fontWeight="bold" mt={1}>
                {parseCurrency(memoizedFreightData.totalValueToday)}
              </Text>
            </Box>
          </Flex>
        </Flex>
        <Flex gridGap={4}>
          <Button variant="outline" onClick={handlePrevious}>
            <RiArrowLeftLine size={20} />
          </Button>
          <Button variant="outline" onClick={handleNext}>
            <RiArrowRightLine size={20} />
          </Button>
        </Flex>
      </Flex>
    </Box>
  )
}
