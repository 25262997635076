import {
  Button,
  HStack,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useColorMode,
  useDisclosure,
} from '@chakra-ui/react'
import { RiArrowDownSLine, RiTruckLine } from 'react-icons/ri'
import { FaFilePdf } from 'react-icons/fa6'
import { ChangeFreightLinkFreightContractModal } from '../../../pages/freight-contracts/list/modals/ChangeFreightContractRelatedFreightModal'
import { FreightContractType } from '../../../services/types/FreightContractType'
import { queryClient } from '../../../config/react-query'
import { useGetFreightContractPdf } from '../../../services/endpoints/freight-contracts/getFreightContractPdf'

interface ButtonsActionsProps {
  freightContract: FreightContractType
}
export function ButtonsActions({ freightContract }: ButtonsActionsProps): JSX.Element {
  const { colorMode } = useColorMode()
  const {
    isOpen: isOpenChangeRelatedFreightModal,
    onClose: onCloseChangeRelatedFreightModal,
    onOpen: onOpenChangeRelatedFreightModal,
  } = useDisclosure()

  const { mutateAsync: getFreightContractPdf, isLoading: isLoadingPdfUrl } = useGetFreightContractPdf({
    onSuccess: () => {
      queryClient.invalidateQueries('list_ctes')
      queryClient.invalidateQueries('show_cte_datamex')
    },
  })
  const handleDownloadPdf = async () => {
    const res = await getFreightContractPdf({
      cte_id: freightContract.cte_id as string,
      freightContractId: freightContract.id,
    })
    window.open(res, '_blank')
  }

  return (
    <>
      {freightContract && (
        <HStack spacing="2">
          <Menu>
            <MenuButton
              as={Button}
              size="xs"
              variant="outline"
              colorScheme="orange"
              rightIcon={<Icon as={RiArrowDownSLine} />}
            >
              Ações
            </MenuButton>
            <MenuList>
              {/* <Ability module="operation" action="generate-pdf-document-handler-cte">
                {cte.pdf_file_url ? (
                  <MenuItem
                    icon={<GrDocumentPdf size="18" />}
                    as={Link}
                    href={cte.pdf_file_url}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Baixar PDF
                  </MenuItem>
                ) : (
                  <MenuItem
                    icon={<GrDocumentPdf size="18" />}
                    isLoading={isLoadingPdfUrl}
                    onClick={async () => {
                      try {
                        const { data: pdfUrl } = await getPdfCte(cte.key)
                        if (pdfUrl) window.open(pdfUrl, '_blank')
                      } catch (error) {
                        toastError({ toast, error })
                      }
                    }}
                  >
                    Baixar PDF
                  </MenuItem>
                )}
              </Ability> */}

              {/* <Ability module="operation" action="change-freight-related-to-cte"> */}
              <MenuItem
                icon={<RiTruckLine size="18" color={colorMode === 'dark' ? 'white' : 'blue'} />}
                onClick={onOpenChangeRelatedFreightModal}
              >
                Mudar frete vinculado
              </MenuItem>
              {/* </Ability> */}
              <MenuItem
                icon={<FaFilePdf size="18" color={colorMode === 'dark' ? 'white' : 'black'} />}
                onClick={handleDownloadPdf}
                isLoading={isLoadingPdfUrl}
              >
                Baixar PDF
              </MenuItem>
            </MenuList>
          </Menu>
        </HStack>
      )}

      <ChangeFreightLinkFreightContractModal
        isOpen={isOpenChangeRelatedFreightModal}
        onClose={() => {
          onCloseChangeRelatedFreightModal()
        }}
        id={freightContract.id}
      />
    </>
  )
}
