import { Box, Flex, Heading, Text, useColorModeValue, Tooltip } from '@chakra-ui/react'
import { format } from 'date-fns'

import { IoMdArrowDropdown, IoMdArrowDropup } from 'react-icons/io'

type MetricCardProps = {
  label: string
  value?: string | Record<string, string>
  yearValue?: string | Record<string, string>
  subMonthValue?: string | Record<string, string>
  filters: { initial_date: Date; final_date: Date }
}

type MetricCardSmallProps = {
  label: string
  value?: string
  variant?: 'positive' | 'negative' | 'neutral'
}

const MetricCardSmall = ({ label, value, variant = 'neutral' }: MetricCardSmallProps): JSX.Element => {
  return (
    <Box aria-label={`${label} ${value} ${variant}`}>
      <Text fontSize="sm" color="gray.500">
        {label}
      </Text>
      <Text fontSize="sm" fontWeight="bold" whiteSpace="pre-wrap">
        {value}
      </Text>
    </Box>
  )
}

const renderMetricValue = (value?: string | Record<string, string>) => {
  if (typeof value === 'string') {
    return <Heading size="2xl">{value}</Heading>
  }

  if (typeof value === 'object' && value !== null) {
    return (
      <Box pb={4}>
        <Flex flexDir="column">
          {Object.entries(value).map(([category, metricValue]) => (
            <Box key={category} my="3">
              <Text fontSize="lg" fontWeight="bold">
                {category}: {metricValue}
              </Text>
            </Box>
          ))}
        </Flex>
      </Box>
    )
  }

  return null
}

const MetricCard = ({ label, value, yearValue, subMonthValue, filters }: MetricCardProps): JSX.Element => {
  const bg = useColorModeValue('white', 'gray.800')
  const shouldWarn =
    label === 'Pontualidade Entrega' && value && typeof value === 'string' && parseFloat(value) < 87

  const removeStringAndConvertToNumber = (metric: string) => {
    if (!value || !metric) return 0
    return Number(metric.replace(/[^0-9]/g, ''))
  }

  const calculateIsPositive = (
    valueParam?: string | Record<string, string>,
    subMonthValueParam?: string | Record<string, string>,
  ) => {
    if (typeof valueParam === 'string' && typeof subMonthValueParam === 'string') {
      return removeStringAndConvertToNumber(valueParam) - removeStringAndConvertToNumber(subMonthValueParam)
    }

    if (typeof valueParam === 'object' && typeof subMonthValueParam === 'object') {
      return 0
    }
    return 0
  }

  const isPositive = calculateIsPositive(value, subMonthValue)

  return (
    <Tooltip label={shouldWarn ? 'Pontualidade abaixo do esperado' : ''} shouldWrapChildren hasArrow>
      <Flex
        h="full"
        p="4"
        bg={bg}
        borderRadius="8"
        shadow="md"
        gridGap="8"
        border={shouldWarn ? '2px solid red' : 'none'}
      >
        <Flex spacing="2" flexDir="column">
          {typeof subMonthValue === 'string' && typeof yearValue === 'string' && (
            <>
              <MetricCardSmall
                label="Mês anterior"
                value={
                  typeof subMonthValue === 'string'
                    ? subMonthValue
                    : subMonthValue
                    ? Object.entries(subMonthValue)
                        .map(([key, val]) => `${key}: ${val}`)
                        .join('\n')
                    : undefined
                }
              />
              <MetricCardSmall
                label="Este ano"
                value={
                  typeof yearValue === 'string'
                    ? yearValue
                    : yearValue
                    ? Object.entries(yearValue)
                        .map(([key, val]) => `${key}: ${val}`)
                        .join('\n')
                    : undefined
                }
              />
            </>
          )}
        </Flex>
        <Flex flexDir="column" borderLeft="1px" pl="1" borderColor="blackAlpha.300" mr={2}>
          <Heading size="md" fontWeight="medium" mt="2">
            {label}:
          </Heading>
          <Text fontSize="sm" color="gray.400">
            {format(filters.initial_date, 'dd MMM')} - {format(filters.final_date, 'dd MMM ')}
          </Text>
          <Flex flexDir="column" flex={1} justifyContent="flex-end">
            <Flex alignItems="flex-end">
              <Text color={shouldWarn ? 'red.500' : ''}>{renderMetricValue(value)}</Text>
              {isPositive !== 0 && (
                <Text ml="4" mb="1" color={isPositive > 0 ? 'green.500' : 'red.500'}>
                  {isPositive > 0 ? <IoMdArrowDropup size="20px" /> : <IoMdArrowDropdown size="20px" />}
                  {isPositive / 100}
                </Text>
              )}
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Tooltip>
  )
}

export default MetricCard
